import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"

export default ({ data }) =>
    <Layout>


      <Img
        className="fox-confessor"
        fixed={data.file.childImageSharp.fixed}
        alt="Fox Confessor cover art"
      />

      <div className="container">
        <div className="row justify-content-start">
          <div className="col-4">
            One of two columns
          </div>
          <div className="col-4">
            One of two columns
          </div>
        </div>


        <div className="row justify-content-center">
          <div className="col">
            One column (JH)
          </div>
        </div>


        <div className="row justify-content-center">
          <div className="col-4">
            One of two columns
          </div>
          <div className="col-4">
            One of two columns
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-4">
            One of two columns
          </div>
          <div className="col-4">
            One of two columns
          </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-4">
            One of two columns
          </div>
          <div className="col-4">
            One of two columns
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-1">
            One of three columns justify-content-between
          </div>
          <div className="col-3">
            One of three columns
          </div>
          <div className="col-1">
            One of three columns
          </div>
        </div>
      </div>      
    </Layout>

export const query = graphql`
  query {
    file(relativePath: { eq: "posts/fox-confessor.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
